import { computed, Inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import { catchError, filter, map, Observable, of, pipe, Subscription, switchMap, take, tap } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { FlashcardsBaseService } from '@stsm/flashcards/services/flashcards-base-service';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { ContentDestinationChange } from '@stsm/flashcards/types/content-destination-change';
import { FlashcardFilterSettings } from '@stsm/flashcards/types/flashcard-filter-settings';
import { FlashcardSelectionInfo } from '@stsm/flashcards/types/flashcard-selection-info';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';

import { FLASHCARD_DATA_STORE } from '../tokens/flashcard-data-store.token';
import { FLASHCARD_TAGS_DIALOG_SERVICE } from '../tokens/flashcard-tags-dialog-service.token';

import { FlashcardDataStoreInterface } from './flashcard-data-store.interface';
import { FlashcardTagsDialogBaseService } from './flashcard-tags-dialog-base.service';
import { MoveFlashcardsDialogService } from './move-flashcards-dialog.service';

export interface FlashcardsSelectionState {
  readonly isBulkEditActive: boolean;
  readonly bulkSelectedFlashcardSelectionInfos: FlashcardSelectionInfo[];
  readonly allAvailableFlashcardsForFilter: FlashcardSelectionInfo[] | undefined;
}

const initialState: FlashcardsSelectionState = {
  isBulkEditActive: false,
  bulkSelectedFlashcardSelectionInfos: [],
  allAvailableFlashcardsForFilter: undefined,
};

export interface SelectedFlashcardIdsMap {
  [flashcardId: number]: boolean;
}

export interface FlashcardsSelectionViewModel extends FlashcardsSelectionState {
  bulkSelectedFlashcardIdsMap: SelectedFlashcardIdsMap;
}

type TrackingBulkEditButton = 'move' | 'add_tags' | 'delete' | 'select_all' | 'clear_all';

@Injectable({
  providedIn: 'root',
})
export class FlashcardsSelectionStore extends ComponentStore<FlashcardsSelectionState> {
  readonly vm$: Observable<FlashcardsSelectionViewModel> = this.state$.pipe(
    map((state: FlashcardsSelectionState) => ({
      ...state,
      bulkSelectedFlashcardIdsMap: state.bulkSelectedFlashcardSelectionInfos.reduce(
        (map: SelectedFlashcardIdsMap, selectionInfo: FlashcardSelectionInfo) => {
          map[selectionInfo.flashcardId] = true;

          return map;
        },
        {} as SelectedFlashcardIdsMap,
      ),
    })),
  );

  readonly vm: Signal<FlashcardsSelectionViewModel | undefined> = toSignal(this.vm$);

  readonly isBulkEditActive: Signal<boolean> = computed(() => this.state().isBulkEditActive);

  readonly bulkSelectedFlashcardSelectionInfos: Signal<FlashcardSelectionInfo[]> = computed(
    () => this.state().bulkSelectedFlashcardSelectionInfos,
  );

  readonly allAvailableFlashcardsForFilter: Signal<FlashcardSelectionInfo[] | undefined> = computed(
    () => this.state().allAvailableFlashcardsForFilter,
  );

  readonly bulkSelectedFlashcardIdsMap: Signal<SelectedFlashcardIdsMap | undefined> = computed(
    () => this.vm()?.bulkSelectedFlashcardIdsMap,
  );

  readonly selectedFlashcardsCount: Signal<number> = computed(() => this.bulkSelectedFlashcardSelectionInfos().length);
  readonly selectableFlashcardsCount: Signal<number | undefined> = computed(() => this.allAvailableFlashcardsForFilter()?.length);

  /* Note: this can only return true after Select All was triggered by the user (accepted limitation)  */
  readonly areAllFlashcardsSelected: Signal<boolean> = computed(
    () => !isNil(this.selectableFlashcardsCount()) && this.selectedFlashcardsCount() === this.selectableFlashcardsCount(),
  );

  readonly selectAll: (studysetId: number) => Subscription = this.effect<number>(
    pipe(
      switchMap((studysetId: number) => {
        const allAvailableFlashcardsForFilter: FlashcardSelectionInfo[] | undefined = this.allAvailableFlashcardsForFilter();

        const selectionInfos$: Observable<FlashcardSelectionInfo[]> = isNil(allAvailableFlashcardsForFilter)
          ? this._getFlashcardSelectionInfosForCurrentFilter(studysetId)
          : of(allAvailableFlashcardsForFilter);

        return selectionInfos$.pipe(
          tap((selectionInfos: FlashcardSelectionInfo[]) => {
            this.setBulkSelectedFlashcardSelectionInfos(selectionInfos);
            this._trackBulkEditEvent('select_all');
          }),
          catchError((error: unknown) => {
            this._loggerService.warn(error);

            return VOID;
          }),
        );
      }),
    ),
  );

  readonly deselectAll: () => void = this.updater((state: FlashcardsSelectionState) => {
    this._trackBulkEditEvent('clear_all');

    return {
      ...state,
      bulkSelectedFlashcardSelectionInfos: [],
    };
  });

  readonly toggleBulkSelectedFlashcardId: (selectionInfo: FlashcardSelectionInfo) => void = this.updater(
    (state: FlashcardsSelectionState, selectionInfo: FlashcardSelectionInfo) => {
      const updatedSelectionInfos = [...state.bulkSelectedFlashcardSelectionInfos];

      const index = updatedSelectionInfos.findIndex((info: FlashcardSelectionInfo) => info.flashcardId === selectionInfo.flashcardId);

      if (index !== -1) {
        updatedSelectionInfos.splice(index, 1);
      } else {
        updatedSelectionInfos.push(selectionInfo);
      }

      return {
        ...state,
        bulkSelectedFlashcardSelectionInfos: updatedSelectionInfos,
      };
    },
  );

  readonly setIsBulkEditActive: (isBulkEditActive: boolean) => void = this.updater(
    (state: FlashcardsSelectionState, isBulkEditActive: boolean) => ({
      ...state,
      isBulkEditActive,
      ...(!isBulkEditActive ? { bulkSelectedFlashcardSelectionInfos: [], allAvailableFlashcardsForFilter: undefined } : {}),
    }),
  );

  readonly setBulkSelectedFlashcardSelectionInfos: (selectionInfos: FlashcardSelectionInfo[]) => void = this.updater(
    (state: FlashcardsSelectionState, selectionInfos: FlashcardSelectionInfo[]) => {
      const filteredSelectionInfos = selectionInfos.filter((selectionInfo: FlashcardSelectionInfo) => selectionInfo.permissions.canEdit);

      return {
        ...state,
        bulkSelectedFlashcardSelectionInfos: [...filteredSelectionInfos],
        allAvailableFlashcardsForFilter: [...filteredSelectionInfos],
      };
    },
  );

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(FLASHCARD_DATA_STORE) private readonly _flashcardDataStore: FlashcardDataStoreInterface,
    @Inject(FLASHCARDS_SERVICE) private readonly _flashcardsService: FlashcardsBaseService,
    private readonly _loggerService: LoggerService,
    @Inject(FLASHCARD_TAGS_DIALOG_SERVICE) private readonly _flashcardTagsDialogService: FlashcardTagsDialogBaseService,
    private readonly _moveFlashcardsDialogService: MoveFlashcardsDialogService,
    private readonly _toastService: ToastService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _translationService: TranslationService,
  ) {
    super(initialState);
  }

  bulkMove(sourceStudyset: Studyset): void {
    this._trackBulkEditEvent('move');

    this._moveFlashcardsDialogService
      .openDialog({
        selectionInfos: this.bulkSelectedFlashcardSelectionInfos(),
        sourceStudyset,
      })
      .subscribe((destination: ContentDestinationChange | undefined) => {
        if (destination) {
          this.setIsBulkEditActive(false);
          this._trackBulkEditEvent('move', true);
        }
      });
  }

  bulkAddTags(studyset: Studyset): void {
    this._trackBulkEditEvent('add_tags');

    this._flashcardTagsDialogService
      .showDialog({
        studyset,
        tags: [],
        isBulkEdit: true,
      })
      .afterClosed()
      .pipe(
        switchMap((tagIds: number[] | undefined) => {
          if (isNil(tagIds) || tagIds.length === 0) {
            return VOID;
          }

          return this._flashcardsService.addTagsToSelectedFlashcards(studyset.id, this.bulkSelectedFlashcardSelectionInfos(), tagIds).pipe(
            tap(() => {
              this._toastService.successToast('FLASHCARDS.ADDED_TAGS');
              this.setIsBulkEditActive(false);
              this._trackBulkEditEvent('add_tags', true);
            }),
          );
        }),
      )
      .subscribe();
  }

  bulkDelete(studyset: Studyset): void {
    this._trackBulkEditEvent('delete');

    this._simpleDialogService
      .scheduleConfirm({
        text: 'FLASHCARDS.BULK_DELETE_CONFIRM',
        confirmText: 'GLOBAL.DELETE',
        isDestructive: true,
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this._flashcardsService.bulkDelete(studyset, this.bulkSelectedFlashcardSelectionInfos())),
        tap(() => {
          this._toastService.successToast(this._translationService.get('FLASHCARDS.DELETED', { count: this.selectedFlashcardsCount() }));
          this.setIsBulkEditActive(false);
          this._trackBulkEditEvent('delete', true);
        }),
      )
      .subscribe();
  }

  private _trackBulkEditEvent(button: TrackingBulkEditButton, isCompleteEvent: boolean = false): void {
    const action = isCompleteEvent ? 'flashcards_bulk_edit_action_complete' : 'flashcards_bulk_edit_btn_click';
    this._analyticsService.trackEvent({ action, properties: { button } });
  }

  private _getFlashcardSelectionInfosForCurrentFilter(studysetId: number): Observable<FlashcardSelectionInfo[]> {
    return this._flashcardDataStore.filterSettings$.pipe(
      take(1),
      switchMap((filterSettings: FlashcardFilterSettings) => {
        return this._flashcardsService.getSelectionInfosForOwnFlashcards(studysetId, filterSettings);
      }),
    );
  }
}
